.section-all-in-one-platform {
    margin-bottom: 50px;
    @media screen and (min-width: 980px) {
        margin-bottom: 135px;
    }
}
.article-all-in-one-platform {
    h2 {
        font-family: var(--rubik);
        letter-spacing: 0;
        font-size: 34px;
        color: var(--primary-max-text-color, #fff);
        margin-bottom: 0;
        text-align: center;
        span {
            color: var(--primary-max-brand-color-300, #fc5b1a);
        }
    }
    p {
        color: var(--secondary-max-text-color, #cddbe5);
        max-width: inherit;
    }
}

.tabs {
    padding-top: 50px;
    @media screen and (min-width: 968px) {
        padding-top: 0;
    }
    .prev {
        @media screen and (max-width: 967px) {
            left: calc(50% - 70px);
            top: 25px;
        }
    }
    .next {
        @media screen and (max-width: 967px) {
            left: calc(50% + 30px);
            top: 25px;
        }
    }
}

.dot {
    display: none;
}

.action {
    padding-top: 20px;
}

.tabpanel {
    text-align: left;
}
