/* ... */
.book-a-demo {
    background-image: url('./bg2.svg');
    background-size: 1367px 1367px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 500px 0;
    margin: -500px 0;
}

:global(.theme-high-contrast) .book-a-demo {
    background-image: none;
}
