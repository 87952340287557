.split {
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    padding: 30px;
    @media screen and (min-width: 980px) {
        padding: 56px 90px 25px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        align-items: center;
    }
    h3 {
        font-family: var(--rubik);
        font-size: 30px;
        color: var(--secondary-max-text-color, #cddbe5);
        margin-top: 0;
    }
    p {
        font-family: var(--roboto);
        font-size: 18px;
        color: var(--secondary-max-text-color, #cddbe5);
        font-weight: 700;
        line-height: 1.25;
        margin-bottom: 18px;
    }
    h5 {
        font-family: var(--roboto);
        font-size: 18px;
        font-weight: 700;
        color: var(--secondary-max-text-color, #cddbe5);
        margin: 35px 0;
    }
    article {
        @media screen and (min-width: 980px) {
            max-width: 425px;
            padding-left: 2px;
        }
    }
    ul {
        padding: 0;
        margin: 0 0 18px 7px;
        list-style: none;
        @media screen and (min-width: 980px) {
            margin: 0 0 18px 15px;
        }
        li {
            margin-left: 0;
            font-weight: 400;
            color: var(--secondary-max-text-color, #cddbe5);
            line-height: 1.35;
            margin-bottom: 15px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                background-color: #19a1ff;
                height: 6px;
                width: 6px;
                border-radius: 50%;
                top: 9px;
                left: -15px;
            }
            a {
                color: #19a1ff;
                &:hover {
                    color: var(--primary-max-brand-color-300, #fc5b1a);
                }
            }
        }
    }
}

.picture {
    display: none;
    @media screen and (min-width: 980px) {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 560px;
            width: 100%;
            height: auto;
        }
    }
}
