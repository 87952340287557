.customersWrapper {
    --mark-h: 1.2;
}
.customers {
    text-align: center;

    h2 {
        position: relative;
        z-index: 1;
        font-size: 30px;
    }
}
.testimonials {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 0 25px;
    margin-top: 45px;
    margin-bottom: 40px;

    > * {
        flex-basis: 0;
        flex-grow: 1;
    }
}
.testimonial {
    min-width: min(300px, 100%);
    background: #fff;
    border-radius: 6px;
    padding: 40px;
    text-align: left;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;

    q {
        font-size: 23px;
        font-family: var(--source);
        font-weight: 700;
        margin-bottom: 1em;
        display: block;
    }

    q::before,
    q::after {
        content: '';
    }

    blockquote {
        margin: 0;
        font-size: 16px;
        line-height: 1.5;

        footer {
            margin-top: 1em;
            display: flex;
            font-size: 14px;
        }
        .date {
            opacity: 0.5;
            margin-left: 2em;
        }
    }

    .columns {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;

        > * {
            flex-basis: 0;
            flex-grow: 1;
        }
    }
    .rate {
        max-width: 150px;
        font-size: 14px;
        text-align: center;

        .logo {
            max-width: 100%;
            height: 38px;
            margin-bottom: 4px;
        }

        .rating {
            display: inline-block;
            opacity: 0.5;
            margin-top: 0.8em;
            font-family: var(--source);
        }
    }
}
.logos {
    max-width: 100%;
    height: auto;
}
