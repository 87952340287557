.max-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media screen and (min-width: 768px) {
        top: 0;
    }
    &.visible {
        background: var(--bg-solid-color, #000f19);
    }
    .nav {
        &::after {
            background-image: linear-gradient(to left, var(--bg-solid-color, #000f19), transparent);
        }
    }
}
