.li {
    flex: 0 1 auto;
    margin: 0 0 3px;
    a,
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        height: 78px;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        text-decoration: none;
        padding: 11px 18px 5px;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
            white-space: initial;
            font-size: 15px;
        }
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 100%;
            width: 80%;
            height: 4px;
            transform: translateX(-50%);
            @media screen and (min-width: 768px) {
                transition: background 0.2s ease;
                height: 3px;
            }
        }
    }
}
