.brands {
    padding: 28px 0;
    margin-bottom: 20px;
    @media screen and (min-width: 980px) {
        margin-bottom: 65px;
        & > div > div {
            transform: translate(12px, -7px);
        }
    }
    h2 {
        font-family: var(--source);
        font-weight: 700;
        font-size: 22px;
        line-height: 1.2;
        color: #9ea6af;
        margin: 0 0 30px;
        text-align: center;
    }
}
