.action {
    padding-top: 20px;
}

.img {
    img {
        max-width: 375px;
        @media screen and (min-width: 980px) {
            transform: translateY(10px);
        }
    }
}
