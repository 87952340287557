.hero {
    position: relative;
    z-index: 0;
    padding: 50px 0 0;
    text-align: center;
    color: var(--primary-max-text-color, #fff);
    min-height: 600px;
    box-sizing: border-box;
    font-family: var(--rubik);
    @media screen and (min-width: 980px) {
        overflow: hidden;
    }
}
