.customersWrapper {
    background: transparent;
    padding: 0;
}
.customers {
    text-align: center;
    h2 {
        font-family: var(--rubik);
        font-weight: 600;
        font-size: 34px;
        color: #fff;
        letter-spacing: 0.5px;
        span {
            color: var(--primary-max-brand-color-300, #fc5b1a);
        }
    }
}

.testimonial {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    q {
        color: #fff;
        font-family: var(--source);
    }
    blockquote {
        color: #fff;
        font-family: var(--roboto);
        .date {
            color: var(--secondary-max-text-color, #cddbe5);
        }
    }
    .rate {
        .rating {
            color: var(--secondary-max-text-color, #cddbe5);
        }
    }
}
