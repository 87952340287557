.max-nav {
    padding: 0;
    position: sticky;
    z-index: 90;
    top: 88px;
    @media screen and (min-width: 768px) {
        top: 0;
    }
    nav {
        margin: 0 auto;
        max-width: 100%;
        display: flex;
        justify-content: flex-end;
        &::after {
            bottom: 0;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
        ul {
            flex-flow: row nowrap;
            overflow-x: scroll;
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
            @media screen and (min-width: 768px) {
                max-width: 850px;
                flex-flow: row wrap;
                overflow: initial;
                margin: 0 -10px;
            }
        }
        .button {
            display: none;
            margin-left: 18px;
        }
        &.visible .button {
            @media screen and (min-width: 768px) {
                display: flex;
            }
        }
    }
}
