.tabpanel {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
    &.dots {
        grid-column: 1;
        grid-row: 2;
        &.hidden {
            display: none;
            @media screen and (min-width: 980px) {
                display: block;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    &.tabs {
        &.hidden {
            @media screen and (min-width: 980px) {
                display: grid;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
