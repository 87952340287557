.li {
    a,
    span {
        font-family: var(--roboto);
        color: #202730;
        background: #fff;
        &::after {
            background: #fff;
        }
    }
    a {
        &.active::after,
        &:hover::after {
            background: #00baff;
        }
    }
    span::after {
        background: #00baff;
    }
}
