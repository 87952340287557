.brands {
    h2,
    h3 {
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 15px;
        line-height: 1.2;
        color: var(--light-text-primary-color, #a7a7a7);
        text-transform: uppercase;
        letter-spacing: 0.12em;
        margin: 0 0 30px;
        text-align: center;
        @media screen and (min-width: 980px) {
            text-align: left;
        }
    }
    ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        @media screen and (min-width: 980px) {
            justify-content: space-between;
        }
        li {
            margin: 20px;
            display: flex;
            align-items: center;
            @media screen and (min-width: 980px) {
                margin: 0;
            }
        }
        figure {
            margin: 0;
            img {
                vertical-align: bottom;
            }
        }
    }
}
.dark {
    h2,
    h3 {
        text-align: left;
        letter-spacing: 0.02em;
        color: #9ea6af;
    }
    ul {
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow: hidden;
        overflow-x: scroll;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;
        gap: 30px;
        &::-webkit-scrollbar {
            display: none;
        }
        li {
            margin: 0;
        }
    }
}
