.split {
    color: var(--primary-max-text-color, #fff);
    article {
        @media screen and (min-width: 980px) {
            padding-bottom: 13px;
        }
    }
    .picture {
        picture {
            display: block;
            @media screen and (min-width: 1440px) {
                transform: translate(32px, 0);
            }
        }
        img {
            max-width: 100%;
            height: auto;
            padding-top: 50px;
            display: block;
            transform: none;
            @media screen and (min-width: 980px) {
                padding-top: 0;
                position: relative;
            }
        }
    }
    h1 {
        font-size: clamp(18px, calc(45px * var(--vwo-font-size-scale, 1)), 50px);
        margin-bottom: 25px;
        font-family: var(--rubik);
        font-weight: 600;
        letter-spacing: 0;
        --underline-offset-bottom: 0.2em;
        u {
            color: var(--primary-max-brand-color-300, #fc5b1a);
        }
        @media screen and (min-width: 768px) {
            font-size: 54px;
            margin-bottom: 31px;
        }
    }
    p {
        font-family: var(--rubik);
        font-weight: 400;
        font-size: clamp(16px, calc(18px * var(--vwo-font-size-scale, 1)), 18px);
        line-height: 1.6;
        letter-spacing: -0.007em;
        margin: 0 0 30px;
        color: var(--secondary-max-text-color, #cddbe5);
    }
    a {
        font-size: 18px;
        padding: 20px 33px;
        border-radius: 24px;
        margin-bottom: 11px;
    }
}
.form {
    margin: 26px auto 0;
    @media only screen and (min-width: 980px) {
        margin-left: 0;
    }
}
