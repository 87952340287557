.arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: url('./assets/arrow.svg') no-repeat center center;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 50%;
    background-size: 12px;
    border: 0;
    width: 45px;
    height: 45px;
    padding: 20px;
    cursor: pointer;
    z-index: 100;
    transition: background 0.5s;
    &.prev {
        left: -15px;
        transform: rotate(180deg) translate(1px, 19px);
        @media screen and (min-width: 980px) {
            left: 20px;
        }
    }
    &.next {
        right: -10px;
        transform: translateY(calc(-50% + 5px));
        @media screen and (min-width: 980px) {
            right: 18px;
        }
    }
    &:hover {
        background: url('./assets/arrow_hover.svg') no-repeat center center;
        background-color: rgba(255, 255, 255, 0.07);
        background-size: 12px;
    }
    @media screen and (min-width: 980px) {
        display: block;
    }
}
