.awards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    container-name: awards;
    container-type: inline-size;
    figure {
        margin: 0;
        padding: 0;
        flex-basis: 0;
        img {
            max-width: 60px;
            height: auto;
            @container (min-width: 680px) {
                max-width: initial;
            }
        }
    }
}
