.grid-container {
    align-items: center;
    @media screen and (min-width: 980px) {
        display: grid;
        grid-template-columns: minmax(max-content, 289px) 1fr;
        grid-template-rows: auto;
    }
}
.col1 {
    text-align: center;
    @media screen and (min-width: 980px) {
        grid-column: 1;
        grid-row: 1;
        img {
            transform: translate(20px, -6px);
        }
    }
}
.col2 {
    @media screen and (min-width: 980px) {
        grid-column: 2;
        grid-row: 1;
        padding-left: 20px;
    }
}
.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 3.3%;
    justify-content: space-around;
    text-align: center;
    @media screen and (min-width: 768px) {
        text-align: unset;
        gap: 15px 5.3%;
    }
}
.flex-item {
    width: 90%;
    @media screen and (min-width: 425px) {
        max-width: 180px;
    }
    @media screen and (min-width: 768px) {
        max-width: 210px;
    }
    @media screen and (min-width: 980px) {
        max-width: 250px;
        box-sizing: border-box;
        padding-left: 20px;
    }
}

.header {
    text-align: center;
    margin-bottom: 35px;
    color: var(--primary-max-text-color, #fff);
}
.title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 21px;
    span {
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }
}
.description {
    color: var(--secondary-max-text-color, #cddbe5);
    font-size: 18px;
    line-height: 1.5;
}
.preName {
    font-size: 60px;
    font-weight: 600;
    margin: 0 0 27px;
    padding: 0;
    line-height: 1;
    color: var(--primary-max-text-color, #fff);
    @media screen and (min-width: 768px) {
        font-size: 80px;
    }
}
.name {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
        font-size: 20px;
    }
}
.desc {
    color: var(--secondary-max-text-color, #cddbe5);
    font-size: 16px;
    line-height: 1.5;
    margin-top: 2px;
}
