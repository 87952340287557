.integrations {
    text-align: center;

    h2 {
        font-size: 34px;
        position: relative;
        z-index: 1;
        margin-bottom: 0.75em;
        font-weight: 600;
        letter-spacing: 0.005em;
        transform: translateX(-6px);
        span {
            color: var(--primary-max-brand-color-300, #fc5b1a);
        }
    }

    h2 + p {
        font-size: 18px;
        margin-bottom: 1.9em;
    }
}

.blok-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px 3%;
    @media screen and (min-width: 1440px) {
        justify-content: center;
        gap: 0 4.5%;
    }
}
