.tabs {
    position: relative;
}
.tablist {
    &.type-tabs {
        position: relative;
        @media screen and (min-width: 980px) {
            display: grid;
            grid-template-rows: auto 1fr;
        }
        > div:nth-child(odd) {
            grid-row: 1;
            align-items: baseline;
        }
        > div:nth-child(even) {
            grid-row: 2;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 2px;
            background: #fff;
        }
    }
    &.type-dots {
        display: grid;
    }
}
.dotlist {
    position: relative;
    z-index: 1000;
    display: flex;
    gap: 22px;
    grid-column: 1;
    grid-row: 1;
    @media screen and (min-width: 980px) {
        gap: 14px;
    }
    .tab {
        padding: 0;
    }
}
.arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: 12px;
    border: 0;
    width: 20px;
    height: 20px;
    padding: 20px;
    cursor: pointer;
    z-index: 100;
    &.prev {
        left: 0;
    }
    &.next {
        right: 0;
    }
    &.isMobileVisible {
        display: block;
    }
    @media screen and (min-width: 980px) {
        display: block;
    }
}
