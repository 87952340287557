.clip {
    width: 0;
    height: 0;
    overflow: hidden;
}
.rate {
    width: 114px;
    height: 20px;
    position: relative;
    display: inline-block;
}
.back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.front {
    width: calc(var(--rate) / 5 * 100%);
    height: 100%;
    background: var(--color, #000);
    position: absolute;
    top: 0;
    left: 0;
}
