.wrapperBG {
    padding-top: 200px;
    margin-top: -200px;
    padding-bottom: 200px;
    margin-bottom: -200px;
    background-image: url('./bg2.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 1534px 1534px;
    background-origin: padding-box, padding-box;
}
