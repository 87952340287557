.wrapper {
    text-align: left;
}
.action {
    padding-top: 20px;
}

.img {
    img {
        max-width: 366px;
    }
}
