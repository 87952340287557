.bookADemo {
    background: rgba(252, 91, 26, 0.1);
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    color: var(--secondary-max-text-color, #cddbe5);
    padding: 20px 20px 44px;
    margin-bottom: 0;
    @media screen and (min-width: 480px) {
        padding: 55px 30px 74px;
        margin-bottom: 70px;
    }
    h2 {
        color: var(--primary-max-text-color, #fff);
        font-family: var(--rubik);
        font-weight: 700;
        font-size: 32px;
        margin: 0 0 17px;
        @media screen and (min-width: 480px) {
            font-size: 36px;
        }
    }
    .description {
        color: var(--secondary-max-text-color, #cddbe5);
        font-size: 18px;
        margin: 0 0 43px;
    }
    button {
        padding: 18px 45px;
        border-radius: 20px;
    }
}
