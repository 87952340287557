.tab-wrapper {
    box-shadow: 0 6px 15px rgb(0 0 0 / 10%);
    border-radius: 6px;
    margin-top: 20px;
    position: relative;
    @media screen and (min-width: 980px) {
        box-shadow: none;
        border-radius: 0;
        margin-top: 0;
    }
    &:first-child {
        margin-top: 0;
    }
    [role='tab'] {
        &:hover {
            svg {
                color: #7990a1;
            }
        }
    }
    &.active {
        [role='tab'] svg {
            color: #7990a1;
        }
    }
    &.accordion {
        > div:nth-child(odd) {
            position: relative;
            &::after {
                display: block;
                content: '';
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
                width: 20px;
                height: 20px;
                background: url('./assets/arrow.svg') no-repeat center center;
                background-size: contain;
                @media screen and (min-width: 980px) {
                    display: none;
                }
            }
        }
        &.active {
            > div:nth-child(odd)::after {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
}
