.li {
    a,
    span {
        font-family: var(--rubik);
        font-size: 14px;
        color: #fff;
        background: transparent;
        height: 49px;
        padding: 11px 16.6px 5px 16.7px;
    }
    a {
        &.active::after,
        &:hover::after {
            background: var(--primary-max-brand-color-300, #fc5b1a);
            border-radius: 15px;
            margin-top: 0.4px;
            height: 5px;
        }
    }
    span::after {
        background: var(--primary-max-brand-color-300, #fc5b1a);
        border-radius: 15px;
        margin-top: 0.4px;
        height: 5px;
    }
}
