.button {
    font-size: 17px;
    border-radius: 100px;
    border: 1px solid var(--primary-btn-bg-color);
    color: var(--primary-btn-text-color);
    background: var(--primary-btn-bg-color);
    @media only screen and (min-width: 768px) {
        font-size: 13px;
        padding: 0.6em 1.24em;
    }
    &:hover,
    &:active {
        background: transparent;
        color: var(--primary-btn-hover-bg-color);
        border-color: var(--primary-btn-hover-bg-color);
    }
}
