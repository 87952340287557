.dot {
    cursor: pointer;
    background-color: #7990a1;
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 12px;
    transition: transform 0.1s ease-in-out;
    @media screen and (min-width: 980px) {
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }
    &.active,
    &:hover {
        background-color: var(--primary-link-color);
        transform: scale(1.5);
    }
}
