.section-all-in-one-platform {
    margin-bottom: 80px;
    > article {
        margin-bottom: 52px;
    }
}
.article-all-in-one-platform {
    h2 {
        font-size: 34px;
        font-weight: 600;
        color: var(--primary-max-text-color, #fff);
        font-family: var(--rubik);
        letter-spacing: 0;
        margin-bottom: 18px;
        span {
            color: var(--primary-max-brand-color-300, #fc5b1a);
        }
    }
    p {
        color: var(--secondary-max-text-color, #cddbe5);
        max-width: inherit;
        letter-spacing: 0.1px;
        font-family: var(--rubik);
        margin-bottom: 45px;
        line-height: 1.5;
    }
}
