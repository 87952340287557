.analysis-business-benefits {
    color: #000;
    font-family: var(--rubik);
    margin-bottom: 50px;
    @media screen and (min-width: 980px) {
        margin-bottom: 130px;
    }
}

.header {
    text-align: center;
    color: #fff;
    line-height: 1.5;
    margin-bottom: 0;
    h2 {
        font-size: 34px;
        letter-spacing: -0.3px;
        line-height: 1.4;
        font-weight: 600;
    }
    span {
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }
}

.columns {
    background: #fff;
    border-radius: 10px;
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
    }
    .col {
        flex-wrap: wrap;
        flex-grow: 1;
    }
}
.col1 {
    padding: 15px;
    box-sizing: border-box;
    display: grid;
    @media screen and (min-width: 768px) {
        padding: 45px 10px 28px 52px;
    }
    @media screen and (min-width: 980px) {
        grid-template-columns: calc(50% - 15px) calc(50% - 15px);
        grid-template-rows: auto auto;
        gap: 30px 40px;
    }

    .grid {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 36px 1fr;
        grid-template-rows: auto;
        gap: 0 4px;
        margin-bottom: 15px;
        &:nth-child(even) {
            gap: 0 14px;
            .icon {
                transform: translate(0, 5px);
            }
        }
        &:nth-child(odd) {
            .icon {
                transform: translate(-1px, 5px);
            }
        }
        @media screen and (min-width: 980px) {
            margin-bottom: 0;
        }
        &:nth-of-type(1) {
            @media screen and (min-width: 980px) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }
        }
        &:nth-of-type(2) {
            @media screen and (min-width: 980px) {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
            }
        }
        &:nth-of-type(3) {
            @media screen and (min-width: 980px) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }
        }
        &:nth-of-type(4) {
            @media screen and (min-width: 980px) {
                grid-column: 2 / span 1;
                grid-row: 2 / span 1;
            }
        }
        .icon {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }
        .top {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;

            p {
                font-size: 35px;
                font-weight: 700;
                margin: 0;
                padding: 0;
                white-space: nowrap;
                span {
                    font-size: 25px;
                    color: #000;
                }
            }
            span {
                font-size: 15px;
                font-weight: 600;
                color: #6ebb85;
            }
        }
    }
}
.col2 {
    padding: 30px;
    background: linear-gradient(90deg, rgba(110, 187, 133, 1) 0%, rgba(166, 228, 131, 1) 31%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media screen and (min-width: 768px) {
        padding: 39px 48px 40px 54px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 0;
    }
}

.grids-col2 {
    display: grid;
    height: 100%;
    margin-top: auto;
    .desc {
        margin-bottom: 20px;
        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 1.35;
            margin: 0;
        }
    }
    .footer {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        align-items: center;
        gap: 15px;
        @media screen and (min-width: 980px) {
            grid-column: 1 / span 1;
            grid-row: 2 / span 2;
            grid-template-columns: 50% 50%;
            grid-template-rows: 1fr;
        }
        .logo {
            display: flex;
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            @media screen and (min-width: 980px) {
                justify-content: flex-end;
                grid-column: 2 / span 1;
                padding-right: 15px;
            }
        }
        .read {
            grid-column: 1 / span 1;
            grid-row: 2 / span 1;
            @media screen and (min-width: 980px) {
                grid-row: 1 / span 1;
            }
        }
    }
}
